/* GLOBAL
---------------------------------------------- */
body {
  font-family: var(--body-font);
  background-color: var(--white);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 85px;
  @media (max-width: 1000px) {
    scroll-padding-top: 70px;
  }
}
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
}
.center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
.text-align-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}
.serif {
  font-family: var(--serif-font);
  font-weight: 400;
}
p:not(:last-of-type) {
  margin-bottom: 1em;
}
p a {
  color: inherit;
}
.lr-page-padding {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}
#smooth-content {
  will-change: transform;
}
.underlined {
  text-decoration: underline;
  text-underline-position: intial;
  text-decoration-thickness: 0.08em;
  transition: text-decoration-color 0.4s ease;
}
@media (hover: hover) {
  .underlined:hover {
    text-decoration-color: transparent;
  }
}
.hidden-on-desktop {
  @media (min-width: 1001px) {
    display: none !important;
  }
}
.hidden-on-mobile {
  @media (max-width: 1001px) {
    display: none !important;
  }
}

/* Lazy loading
---------------------------------------------- */

[data-lazy] {
  visibility: hidden;
}

.image-wrap {
  background-size: cover;
  min-width: 0;
  background-color: var(--white);
}
