@font-face {
  font-family: "AT Surt Ext";
  src: url("../fonts/AT-Surt-Extd.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "AT Surt Ext";
  src: url("../fonts/AT-Surt-SemiBold-Extd.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Morganite";
  src: url("../fonts/Morganite-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
