@import "_reset";
@import "_fonts";
@import "_variables";
@import "_common";

/* SITE SPECIFIC
---------------------------------------------- */
html {
  scroll-padding-top: 16rem;
}

/* LOADER
---------------------------------------------- */
.loader {
  height: 100vh;
  height: 100svh;
  height: 100dvh;
  background-color: var(--red);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: top 1.1s ease-in-out;
}
.loader__text {
  font-family: var(--serif-font);
  letter-spacing: initial;
  font-size: var(--huge-font-size);
  max-width: 36vw;
  margin-left: var(--lr-page-padding);
  padding-top: var(--lr-page-padding);
}
.loader__logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 var(--lr-page-padding);
  @media (max-width: 1000px) {
    position: initial;
    top: initial;
    left: initial;
    -ms-transform: initial;
    transform: initial;
    width: initial;
    display: initial;
    justify-content: initial;
    padding: initial;
  }
}
.loader__logo--left {
  @media (max-width: 1000px) {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50vh;
    height: 50svh;
    height: 50dvh;
  }
}
.loader__logo--left svg {
  @media (max-width: 1000px) {
    position: absolute;
  }
}
.loader__logo--right {
  @media (max-width: 1000px) {
    position: absolute;
    bottom: 0;
    height: 50vh;
    height: 50svh;
    height: 50dvh;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.loader__logo--right svg {
  @media (max-width: 1000px) {
    position: absolute;
    bottom: 0;
  }
}
.loader__logo svg {
  height: 6.6vw;
  width: auto;
  @media (max-width: 1000px) {
    height: 12.5vw;
  }
}

/* NAVBAR
---------------------------------------------- */
header {
  padding-top: calc(var(--lr-page-padding) * 0.7);
  position: fixed;
  width: 100%;
  z-index: 9;
  @media (max-width: 1000px) {
    padding-top: calc(var(--lr-page-padding) * 1.5);
  }
}
.navbar__container {
  display: flex;
  column-gap: 4vw;
  align-items: baseline;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    row-gap: 0.35rem;
    column-gap: 1.3rem;
    justify-content: center;
  }
}
.work-template .navbar__container {
  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }
}
.navbar-logo {
  display: inline-block;
}
.navbar-logo__container {
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: span 3;
    width: 100%;
  }
}
.navbar-logo svg {
  width: 152px;
  height: auto;
  @media (max-width: 1000px) {
    padding-bottom: 3px;
  }
}
.navbar-work-button__container {
  display: flex;
  align-items: baseline;
  gap: 2vw;
  @media (max-width: 1000px) {
    justify-content: center;
  }
}
.navbar-text-button {
  font-size: var(--extra-small-font-size);
  @media (max-width: 1000px) {
    font-size: var(--small-font-size);
  }
}
.work-template .navbar-text-button {
  @media (max-width: 1000px) {
    font-size: var(--extra-small-font-size);
  }
}
.navbar-about-button__container {
  margin-left: auto;
  display: flex;
  align-items: baseline;
  gap: 2vw;
  @media (max-width: 1000px) {
    margin-left: initial;
    order: 1;
    text-align: center;
  }
}
.work-template .navbar-about-button__container {
  @media (max-width: 1000px) {
    display: none;
  }
}
.navbar-contact-button__container {
  padding-right: 2vw;
  @media (max-width: 1000px) {
    display: block;
    order: 2;
    align-items: start;
    padding-left: 0rem;
    text-align: initial;
    padding-right: 0vw;
  }
}

/* HOME BUTTONS
---------------------------------------------- */
body {
  transition: background-color 0.4s ease;
}
.subnav-buttons {
  font-size: var(--extra-tiny-font-size);
  @media (max-width: 1000px) {
    position: fixed;
    left: 0;
    padding: 0 var(--lr-page-padding);
    margin-top: 2rem;
    font-size: var(--extra-small-font-size);
    width: 100%;
  }
}
.subnav-buttons__options {
  display: flex;
  gap: 2vw;
  @media (max-width: 1000px) {
    gap: 4vw;
    justify-content: center;
    width: 100%;
  }
}
.subnav-buttons__option {
  cursor: pointer;
  font-weight: normal;
  position: relative;
}
.subnav-buttons__option {
  margin-bottom: -0.65rem;
  opacity: 0.3;
  transition: opacity 0.4s ease-in;
}

.subnav-buttons__option.active {
  opacity: 1;
}

/* HOME 
---------------------------------------------- */
.works-items {
  padding-top: 9rem;
  @media (max-width: 1000px) {
    padding-top: 10.5rem;
  }
}
.works-items__grid {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-flow: row dense;
    row-gap: 15px;
  }
}
.grid-item__wrapper--landscape {
  @media (max-width: 1000px) {
    grid-column: span 2;
  }
}
.grid-item {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  font-family: var(--condensed-font);
  font-size: var(--extra-huge-font-size);
  transition: opacity 0.4s ease;
  @media (max-width: 1000px) {
    padding: 0.5rem;
  }
}
.grid-item.hidden {
  opacity: 0.05;
  pointer-events: none;
}
.grid-item__image {
  height: 8vw;
  width: auto;
  @media (max-width: 1000px) {
    height: 20vw;
  }
}
.grid-item__image.landscape,
.grid-item__image.square {
  height: auto;
  width: 8vw;
  @media (max-width: 1000px) {
    width: 20vw;
  }
}
.grid-item__image.landscape {
  @media (max-width: 1000px) {
    height: 20vw;
    width: auto;
  }
}
.grid-item__title {
  display: none;
}
/* WORK ITEM
---------------------------------------------- */
.work-items {
  font-size: var(--small-font-size);
  min-height: 100vh;
  min-height: 100svh;
  min-height: 100dvh;
  padding-top: 5rem;
  @media (max-width: 1000px) {
    padding-top: 4.6rem;
  }
}
.work-details {
  margin-left: calc(149px + 4vw);
  @media (max-width: 1000px) {
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
}
.work-title {
  font-size: var(--huge-font-size);
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  @media (max-width: 1000px) {
    font-size: var(--small-font-size);
  }
}

.work-specs {
  font-family: var(--serif-font);
  font-optical-sizing: auto;
  letter-spacing: initial;
  font-style: italic;
  font-size: var(--huge-font-size);
  display: inline-block;
  margin-left: 0.9rem;
}
.work-category {
  font-size: var(--medium-font-size);
  display: inline-block;
  text-transform: uppercase;
  margin-left: 0.6rem;
}
.work-intro-text {
  margin-top: 3rem;
  margin-left: calc(149px + 4vw);
  font-size: var(--huge-font-size);
  font-family: var(--serif-font);
  font-optical-sizing: auto;
  letter-spacing: initial;
}
.work-content {
  margin-top: 8rem;
  margin-bottom: 2rem;
  @media (max-width: 1000px) {
    margin-top: 3.5rem;
  }
}
.work-video {
  margin: 0 auto;
  margin-bottom: 8rem;
  width: calc(100vw - calc(var(--lr-page-padding) * 2));
  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: var(--lr-page-padding);
  }
  & figure {
    position: relative;
    padding-bottom: 56.25%;
  }
  & iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.work-gallery {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--lr-page-padding);
}
.work-gallery--two-column-grid {
  grid-template-columns: 1fr 1fr;
  row-gap: 6rem;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    row-gap: var(--lr-page-padding);
  }
}

.work-gallery__image-wrapper {
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
  }
}
.work-gallery--two-column-grid .work-gallery__image-wrapper {
  width: initial;
  margin-right: 16.66vw;
  @media (max-width: 1000px) {
    margin-right: initial;
  }
}
.work-gallery__image {
  width: 100%;
  height: auto;
}
.work-credits {
  z-index: 10;
  position: fixed;
  left: var(--lr-page-padding);
  bottom: var(--lr-page-padding);
  font-size: var(--tiny-font-size);
  text-transform: uppercase;
  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 0.3rem;
    width: 100%;
  }
}
.work-credits__title {
  display: inline-block;
  margin-right: 0.7rem;
}
.work-credits__item {
  display: inline-block;
}
.work-credits__names {
  display: inline-block;
  & ul {
    margin-left: 0.7rem;
    padding-inline-start: 0px;
  }
  & li {
    display: inline-block;
    margin-right: 0.7rem;
  }
}

/* Work credits buttons for mobile */
.credits-button {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.next-project-button {
  display: block;
  padding-left: 1.5rem;
}
/* Work credits modal for mobile */
.mob-work-credits {
  width: 100%;
  padding-top: 4.6rem;
  padding-bottom: var(--lr-page-padding);
  position: fixed;
  z-index: 1;
  background-color: #1a1a1a;
  color: var(--white);
  min-height: 100vh;
  min-height: 100svh;
  min-height: 100dvh;
  top: 100%;
  left: 0;
}
.mob-work-credits__title {
  font-size: var(--small-font-size);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 4.5rem;
}
.mob-work-credits__intro-text {
  font-family: var(--serif-font);
  font-optical-sizing: auto;
  letter-spacing: initial;
  text-align: center;
  font-size: var(--extra-large-font-size);
  margin-bottom: 3.5em;
}
.mob-work-credits__collaborators-title {
  font-size: var(--small-font-size);
  font-weight: 600;
  text-transform: uppercase;
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-position: intial;
  text-decoration-thickness: 0.08em;
  text-align: center;
  margin-bottom: 1.6rem;
}
.mob-work-credits__item {
  text-transform: uppercase;
  text-align: center;
  font-size: var(--extra-small-font-size);
  margin-bottom: 1rem;
}
.mob-work-credits__names {
  text-transform: initial;
  text-align: center;
  font-size: var(--extra-small-font-size);
  font-weight: 600;
  & ul {
    display: flex;
    flex-direction: column;
    padding-inline-start: 0px;
    list-style: none;
  }
  & li {
    margin-top: 0.15rem;
  }
}

/* CONTACT
---------------------------------------------- */
.contact-items {
  font-size: var(--small-font-size);
  height: 100vh;
  height: 100svh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 440px;
  padding-bottom: var(--lr-page-padding);
}
.contact-items__links {
  padding: 2vw 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-items__link {
  padding: 1.6vw 20px;
  line-height: 1.65;
  @media (max-width: 1000px) {
    padding: 1rem 10px;
    line-height: 1.65;
  }
}
.contact-links {
  @media (min-width: 1001px) {
  }
  display: block;
}
.contact-items__link a {
  font-size: var(--medium-font-size);
  font-weight: 400;
}
.contact-items__message {
  min-height: 3rem;
  font-size: var(--large-font-size);
  font-weight: 600;
  max-width: 400px;
  margin: 0 auto;
  @media (max-width: 1000px) {
    max-width: 180px;
    margin: 0 auto;
  }
}
/* ABOUT
---------------------------------------------- */
.about-items {
  font-size: var(--small-font-size);
  height: 100vh;
  padding-top: 5rem;
  gap: 5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-left: calc(149px + 4vw);
  @media (max-width: 1000px) {
    margin-left: 0;
    display: block;
    height: initial;
    overflow: initial;
    position: relative;
    padding-top: 4.6rem;
    padding-left: var(--lr-page-padding);
    padding-right: var(--lr-page-padding);
  }
}
.mob-about-nav-buttons {
  position: fixed;
  display: none;
  text-transform: uppercase;
  font-size: var(--small-font-size);
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    z-index: 5;
  }
}
.mob-about-nav-button.active {
  font-weight: 600;
}
.about-item {
  @media (max-width: 1000px) {
    margin-top: 9rem;
    margin-bottom: 16rem;
  }
}
.about-item.hidden {
  display: none;
}
.about-items__intro-text {
  font-size: var(--huge-font-size);
  font-family: var(--serif-font);
  letter-spacing: initial;
  @media (max-width: 1000px) {
    font-size: var(--extra-large-font-size);
  }
}
.about-items__content {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  text-transform: uppercase;
  flex-grow: 1;
  overflow: hidden;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    overflow: initial;
    gap: 16rem;
  }
}
.about-section-title {
  font-size: var(--extra-small-font-size);
  @media (max-width: 1000px) {
    display: none;
  }
}

/* Clients */
.about-clients {
  overflow: hidden;
  height: 100%;
}
.about-clients__title {
  padding-bottom: 1rem;
}
.about-clients__list-wrapper {
  overflow: scroll;
  height: 100%;
  padding-bottom: 2.5rem;
  @media (max-width: 1000px) {
    padding-bottom: 0rem;
    overflow: initial;
  }
}
.about-clients__list-wrapper::-webkit-scrollbar {
  display: none;
}
.about-clients__list ul {
  list-style-type: none;
  padding-left: 0rem;
}
.about-clients__list li {
  font-size: var(--extra-tiny-font-size);
  @media (max-width: 1000px) {
    font-size: var(--medium-font-size);
    text-align: center;
  }
}
.about-clients__list li:not(:last-of-type) {
  margin-bottom: 0.8rem;
  @media (max-width: 1000px) {
    margin-bottom: 0.4rem;
  }
}
/* Press*/
.about-press {
  overflow: hidden;
  height: 100%;
}
.about-press__title {
  padding-bottom: 1rem;
}
.about-press__list-wrapper {
  overflow: scroll;
  height: 100%;
  @media (max-width: 1000px) {
    overflow: initial;
  }
}
.about-press__list-wrapper::-webkit-scrollbar {
  display: none;
}
.about-press__list {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  margin-bottom: 1.5rem;
  @media (max-width: 1000px) {
    gap: 5rem;
  }
}
.about-press__item {
  position: relative;
  height: 20vh;
}
.about-press__item-title,
.about-press__item-date {
  font-size: var(--extra-tiny-font-size);
  @media (max-width: 1000px) {
    font-size: var(--large-font-size);
    font-weight: 600;
    text-align: center;
  }
}
.about-press__item-date {
  margin-top: 0.8rem;
  @media (max-width: 1000px) {
    font-size: var(--tiny-font-size);
    font-weight: 400;
    margin-top: 0.5rem;
  }
}
.about-press__item-image {
  margin-top: 0.8rem;
  width: auto;
  height: 12vh;
  opacity: 0;
  transition: opacity 0.4s ease;
  @media (max-width: 1000px) {
    margin-left: auto;
    margin-right: auto;
    width: 15vw;
    height: auto;
  }
  @media (max-width: 500px) {
    width: 25vw;
  }
}
@media (hover: hover) {
  .about-press__item:hover .about-press__item-image,
  .about-press__item:hover .about-press__item-title--text {
    opacity: 1;
  }
}
/* Ten things */
.about-ten-things {
  overflow: scroll;
  @media (max-width: 1000px) {
    overflow: auto;
  }
}
.about-ten-things__list {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 100%;
  max-height: 100%;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    height: initial;
    max-height: initial;
    @media (max-width: 1000px) {
      gap: 5rem;
    }
  }
}
.about-ten-things__title {
  grid-column: span 2;
}
.about-ten-things__item {
  position: relative;
}
.about-ten-things__item-title {
  font-size: var(--extra-tiny-font-size);
  @media (max-width: 1000px) {
    font-size: var(--extra-small-font-size);
    text-align: center;
  }
}
.about-ten-things__item-title--text {
  opacity: 0;
  transition: opacity 0.4s ease;
  @media (max-width: 1000px) {
    opacity: 1;
    display: block;
    font-size: var(--extra-large-font-size);
    font-weight: 600;
    margin-top: 0.7rem;
  }
}
.about-ten-things__item-image {
  width: auto;
  position: absolute;
  height: 70%;
  width: auto;
  left: 0;
  top: 3.5vh;
  opacity: 0;
  transition: opacity 0.4s ease;
  @media (max-width: 1000px) {
    margin-top: 0.8rem;
    position: initial;
    height: auto;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (hover: hover) {
  .about-ten-things__item:hover .about-ten-things__item-image,
  .about-ten-things__item:hover .about-ten-things__item-title--text {
    opacity: 1;
  }
}

.about-items__content img.in-view {
  @media (max-width: 1000px) {
    opacity: 1;
  }
}
