/* Variables
---------------------------------------------- */
:root {
  letter-spacing: 0.05em;

  --vh: 1;

  --font-size: 20px;
  --body-font: "AT Surt Ext", sans-serif;
  --serif-font: "Old Standard TT", serif;
  --condensed-font: "Morganite", "AT Surt Ext", sans-serif;

  --extra-tiny-font-size: 0.5rem;
  --tiny-font-size: 0.6rem;
  --extra-small-font-size: 0.7rem;
  --small-font-size: 0.8rem;
  --medium-font-size: 0.95rem;
  --large-font-size: 1.15rem;
  --extra-large-font-size: 1.5rem;
  --huge-font-size: 1.8rem;
  --extra-huge-font-size: 3rem;
  --massive-font-size: 4rem;

  /* --extra-tiny-font-size: 0.6vw;
  --tiny-font-size: 0.7vw;
  --extra-small-font-size: 0.8vw;
  --small-font-size: 1vw;
  --medium-font-size: 1.2vw;
  --large-font-size: 1.4vw;
  --extra-large-font-size: 2vw;
  --huge-font-size: 2.9vw;
  --extra-huge-font-size: 3.4vw;
  --massive-font-size: 3.7vw;
  --extra-massive-font-size: 4.2vw;
  --ginormous-font-size: 8vw; */

  --regular-font-size: var(--font-size);

  --black: black;
  --white: white;
  --red: #fe4735;

  --lr-page-padding: 0.8vw;

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }

  /* Variables for-small-tablet-below
  ---------------------------------------------- */
  @media (max-width: 1000px) {
    --lr-page-padding: 10px;
    --extra-tiny-font-size: 0.3rem;
    --tiny-font-size: 0.4rem;
    --extra-small-font-size: 0.45rem;
    --small-font-size: 0.65rem;
    --medium-font-size: 0.75rem;
    --large-font-size: 0.85rem;
    --extra-large-font-size: 1rem;
    --huge-font-size: 1.2rem;
    --extra-huge-font-size: 1.6rem;
    --massive-font-size: 2rem;
  }
}
