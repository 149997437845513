*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
:where(img, picture, video, canvas, svg) {
  display: block;
  max-width: 100%;
}
:where(iframe) {
  border: none;
  display: block;
}
:where(input, button, textarea, select) {
  font: inherit;
}
:where(h1, h2, h3, h4, h5, h6) {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}
:where(button) {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  padding: 0;
  font: inherit;
  text-align: inherit;
}
:where(a) {
  color: inherit;
  text-decoration: none;
}
